<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text class="py-0">
                        <div>
                            <v-row>
                                <v-col class="pt-0">
                                    <v-data-table :headers="activeHeaders" :sort-by="['custom']"
                                        :custom-sort="customSort" :items="tablePositions" hide-default-footer 
                                        class="elevation-1" mobile-breakpoint="0" dense>

                                        <template v-slot:item.position="{ index }">
                                            <h6 class="ma-0 font-weight-medium">
                                                {{ index + 1 }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.points="{ item }">
                                            <h6 class="ma-0 font-weight-medium">
                                                {{ item.points }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDifferenceFor="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceFor ? item.pointsDifferenceFor : '-' }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDifferenceAgainst="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceAgainst ? item.pointsDifferenceAgainst : '-' }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDiff="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceFor - item.pointsDifferenceAgainst }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.teamEnt.name="{ item }">
                                            <div style="display: flex;">
                                                <div>
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.teamEnt.code}.WEBP`"
                                                        contain max-width="30" class="mr-2 img_border"></v-img>
                                                </div>
                                                <div>{{ item.teamEnt.name }}</div>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="scrollable-row">
            <v-col cols="12" class="pb-0">
                <v-card>
                    <v-card-text class="py-0">
                        <div>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-iterator :items="fixtureList" hide-default-footer>
                                        <template v-slot:default="props">
                                            <v-row justify="center">
                                                <v-col v-for="(item, index) in props.items" :key="index" cols="12"
                                                    lg="8" class="pa-0 mb-2">
                                                    <list-card-row-permutation :id="`match-${index}`"
                                                        :ref="`match-${index}`" :fixture="item"
                                                        :progressbar-color="item.progressColor" footer-btn="CHAT"
                                                        footer-btn-color="default" :editing="true"
                                                        footer-btn-two="PROFILE" footer-btn-color-two="default"
                                                        class="fixture" @marginSelected="selectionMade(index);"
                                                        @marginSelection="marginSelectionMade"
                                                        @marginUnSelected="undoSelection(index)" @resultSelected="resultSelected" />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-iterator>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ChartCard from '@/components/card/ChartCard'
import ListCardRowPermutation from '@/components/card/listCard/ListCardRowPermutation'
import { deepCopyFunction } from '@/utils/arrayUtils.js'
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['stage'],
    components: {
        ChartCard,
        ListCardRowPermutation
    },
    data() {
        return {
            roundRobinHeaders: [
                { text: '', value: 'position', sortable: true },
                { text: 'Team', value: 'teamEnt.name', sortable: true },
                { text: 'P', value: 'matchday', sortable: true },
                { text: 'Win', value: 'win', sortable: true },
                { text: 'Loss', value: 'loss', sortable: true },
                { text: 'Draw', value: 'draw', sortable: true },
                { text: '+/-', value: 'pointsDiff', sortable: true },
                { text: 'Points', value: 'points', sortable: true },
            ],
            knockoutHeaders: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'homeLogo',
                },
                { text: 'Home', value: 'home' },
                { text: '', value: 'time' },

                { text: 'Away', value: 'away' },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'awayLogo',
                },
                { text: 'Date', value: 'date' },
            ],
            teams: [],
            predictions:[],
            standings: []
        }
    },
    computed: {
        ...mapGetters(["loggedInUser", "getFixtures", "getMunsterRoundRobin", "getLeinsterRoundRobin"]),
        title() {
            let tournment = this.stage.charAt(0).toUpperCase() + this.stage.slice(1);
            if (true) {
                return tournment + " Senior Hurling Championship";
            }
        },
        competitionRounds() {
            return [...[...this.getFixtures.reduce((set, fixture) => set.add(fixture.round), new Set())]].filter(it => it != "Round Robin").sort();
        },
        tablePositions() {
            let currentStandings = [];
            if (this.stage == 'munster') {
                currentStandings = deepCopyFunction([...this.getMunsterRoundRobin]);
            } else if (this.stage == 'leinster') {
                currentStandings = deepCopyFunction([...this.getLeinsterRoundRobin]);
            }
            for(let i=0;i<this.predictions.length;i++){
                let prediction = this.predictions[i];
                let fixture = this.getFixtures.find(it => it.id == prediction.fixtureId);
                let homeTeamPerformance = currentStandings.find(it => it.teamEnt.name == fixture.homeTeam);
                let awayTeamPerformance = currentStandings.find(it => it.teamEnt.name == fixture.awayTeam);
                homeTeamPerformance.matchday += 1;
                awayTeamPerformance.matchday += 1;
                if(prediction.result == 1){
                    homeTeamPerformance.points += 2;
                    if(prediction.margin){
                        homeTeamPerformance.pointsDifferenceFor += prediction.margin;
                        awayTeamPerformance.pointsDifferenceAgainst += prediction.margin;
                    }
                } else if(prediction.result == 2){
                    awayTeamPerformance.points += 2;
                    if(prediction.margin){
                        homeTeamPerformance.pointsDifferenceAgainst += prediction.margin;
                        awayTeamPerformance.pointsDifferenceFor += prediction.margin;
                    }
                } else if(prediction.result == -1){
                    homeTeamPerformance.points += 1;
                    awayTeamPerformance.points += 1;
                }
            }
            return currentStandings;
        },
        fixtureList() {
            let fixturesFromDb = [...this.getFixtures.filter(it => it.matchday > 1 && it.stage == 'Munster')];
            for (let i = 0; i < fixturesFromDb.length; i++) {
                fixturesFromDb[i].matchResult = null;
                fixturesFromDb[i].homeGoals = null;
                fixturesFromDb[i].homePoints = null;
                fixturesFromDb[i].awayGoals = null;
                fixturesFromDb[i].awayPoints = null;
            }
            return fixturesFromDb;
        },
        activeHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                this.roundRobinHeaders.find(it => it.value == "points").text = "Pts";
                return this.roundRobinHeaders.filter(it => it.value != 'win' & it.value != 'loss' & it.value != 'draw');
            } else {
                return this.roundRobinHeaders;
            }
        }
    },
    methods: {
        ...mapActions(["fetchFixtures", "fetchCompetitionStandings", "fetchFixtures"]),
        matchTimeString(item) {
            return item.matchResult ? item.homeGoals + ":" + item.homePoints + ' - ' + item.awayGoals + ":" + item.awayPoints : item.time
        },
        formatDate(date) {
            return date.split('-').reverse().map((part, index) => index === 2 ? part.slice(-2) : part).join('/');
        },
        formatDateUS(date) {
            return date.replace(/(\d+)\/(\d+)\/(\d{2})(\d{2})/, (_, m, d, __, y) => `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`);
        },
        selectionMade(index) {
            if (index < this.fixtureList.length - 1) {
                document.getElementById(`match-${index + 1}`).scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
        },
        undoSelection(result){
            const existingIndex = this.predictions.findIndex(prediction => prediction.fixtureId === result.fixtureId);
            this.predictions.pop(existingIndex); //TODO - not working
        },
        marginSelectionMade(margin){
            let index = this.predictions.findIndex(it => it.fixtureId == margin.fixtureId);
            this.$set(this.predictions, index, {
            ...this.predictions[index],
                margin: margin.margin
            });
        },
        resultSelected(result){
            const existingIndex = this.predictions.findIndex(prediction => prediction.fixtureId === result.fixtureId);
            if (existingIndex !== -1) {
                this.$set(this.predictions, existingIndex, result);
            } else {
                this.predictions.push(result);
            }
        },
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                // A) Compare points: higher points come first
                if (a.points !== b.points) {
                    return sortDesc[0] ? a.points - b.points : b.points - a.points;
                }

                // B) If points are the same, compare head-to-head record
                const headToHeadComparison = this.compareHeadToHead(a, b);
                if (headToHeadComparison !== 0) {
                    return headToHeadComparison;
                }

                // C) If points and head-to-head are the same, compare score difference
                return sortDesc[0] ? (a.pointsDifferenceFor - a.pointsDifferenceAgainst) - (b.pointsDifferenceFor - b.pointsDifferenceAgainst) : (b.pointsDifferenceFor - b.pointsDifferenceAgainst) - (a.pointsDifferenceFor - a.pointsDifferenceAgainst);
            });

            return items;
        },
        compareHeadToHead(teamA, teamB) {
            let fixture = this.getFixtures.find(it => (it.homeTeam == teamA.teamEnt.name && it.awayTeam == teamB.teamEnt.name) || it.homeTeam == teamB.teamEnt.name && it.awayTeam == teamA.teamEnt.name);
            let fixtureResult = null;
            if(fixture && fixture.matchResult == null){
                let prediction = this.predictions.find(it => it.fixtureId == fixture.id)
                if(prediction){
                    fixtureResult = prediction.matchResult;
                }
            } else {
                if(fixture){
                    fixtureResult = fixture.matchResult;
                }
            }
            if(fixtureResult == "HOME_WIN"){
                return -11;
            } else if(fixtureResult == "AWAY_WIN"){
                return 1;
            } else {
                return 0;
            }
        }
    },
    mounted() {
        this.fetchCompetitionStandings(3) //TODO - hardcoded comp
        this.fetchFixtures(3); //TODO - hardcoded comp
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header th .v-icon {
    display: none;
}

/* Ensure the headers still look clickable */
.v-data-table-header th {
    cursor: pointer;
}

.img_border {
    border-style: solid;
    border-width: 2px;
}

.custom-card-title {
    word-break: keep-all !important;
    white-space: normal !important;
}

.scrollable-row {
  height: 50vh;
  overflow-y: auto;
  margin-top: 16px; /* Space between the fixed row and scrollable row */
}

.v-row:first-of-type {
  position: sticky;
  top: 0;
  background-color: white; /* Ensure background is visible when scrolling */
  z-index: 1; /* Keep it above the scrolling content */
}

</style>